













































































































































































































































































































































































































































































































import Enum from "@/config/enum";
import EventUtil from "@/config/util/eventUtil";
import { EventApi } from "@/networks/EventApi";
import GeneralApi from "@/networks/GeneralApi";
import Office from "@/views/components/inputs/office.vue";
import { AxiosResponse } from "axios";
import moment from "moment-jalaali";
import { Component, Vue, Watch } from "vue-property-decorator";
import DatePicker from "vue-persian-datetime-picker";
import store from "../../../store";

@Component({
  components: {
    Office,
    DatePicker
  }
})
export default class ConfirmPayment extends Vue {
  private date = {
    year: 1401,
    month: moment().format("jMM") == "12" ? "01" : moment().format("jM"),
    day: ""
  };
  private data: Record<string, any> = {
    creator: {},
    event: {},
    payment: {},
    user: {}
  };
  private month = Enum.months;
  private online = Enum.onlineTypes;
  private grades: any = Enum.grades;
  private times: Array<Record<string, string>> = [];
  private errors: Record<string, string> = {};
  private cities: any = [];
  private countries: any = [];
  private languages: any = Enum.languages;
  private languages_level: any = Enum.language_level;
  private form: any = {
    date: null,
    time: null,
    study: {},
    detail: {
      how_to_go2tr: ""
    },
    lang: {}
  };
  edit: boolean | undefined = false;
  days: { text: string; value: string }[] | undefined = [];
  submit_data: any = {};
  mounted() {
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
    this.getData();
    this.getDays();
    this.getCountries();
    for (let i = 10; i <= 21; i++) {
      this.times.push({
        text: `ساعت ${i}:00`,
        value: `${("00" + i).substr(-2)}:00`,
        cnt: "0"
      });
    }
  }
  private async getCountries() {
    let res = await new GeneralApi().countries({});
    this.countries = res.data.data;
    res = await new GeneralApi().province({});
    this.cities = res.data.data;
  }
  persianDate(date: any) {
    return moment(date).format("jDD jMMMM jYYYY");
  }
  public async submit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (!valid) {
      return;
    }
    try {
      if (
        this.form.study.study_field ||
        this.form.study.university ||
        this.form.study.grade ||
        this.form.study.average
      ) {
        if (
          !this.form.study.study_field ||
          !this.form.study.university ||
          !this.form.study.grade ||
          !this.form.study.average
        ) {
          store.commit("showError", {
            message: "لطفا مشخصات تحصیلی را کامل کنید",
            color: "error"
          });
          return;
        }
      }
      if (
        this.form.lang.language ||
        this.form.lang.docs ||
        this.form.lang.level
      ) {
        if (
          !this.form.lang.language ||
          !this.form.lang.docs ||
          !this.form.lang.level
        ) {
          store.commit("showError", {
            message: "لطفا مشخصات زبان را کامل کنید",
            color: "error"
          });
          return;
        }
      }
      if (!this.data.event.time) {
        if (!this.form.date || !this.form.time) {
          store.commit("showError", {
            message: "لطفا زمان مشاوره را انتخاب کنید",
            color: "error"
          });
          return;
        }
      }
      if (!this.data.event.office_id) {
        if (!this.form.office_id) {
          store.commit("showError", {
            message: "لطفا دفتر را انتخاب کنید",
            color: "error"
          });
          return;
        }
      }
      const res: AxiosResponse = await new EventApi().setConfirmPayment(
        this.form,
        this.$route.params.id
      );
      this.submit_data = res.data.data;
      this.errors = {};
      store.commit("showError", {
        message: res.data.message || "با موفقیت ثبت شد",
        color: "success"
      });
      this.edit = true;
    } catch (error: any) {
      if (error.response && error.response?.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "error"
        });
      }
    }
  }

  public async getData(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().confirmPayment(
        this.$route.params.id
      );
      this.data = res.data.data;
      this.form.date = this.data.event.date;
      this.form.time = this.data.event.time;
      this.form.office_id = this.data.event.office_id;
    } catch (error: any) {
      this.errors = error.response.data;
    }
  }

  public async checkAvailability(): Promise<void> {
    this.times = await new EventUtil().checkAvailability(
      this.data.event.type_original,
      this.form.date,
      this.form.office_id ? this.form.office_id : this.data.event.office_id,
      this.times
    );
  }

  @Watch("date.day")
  @Watch("date.month")
  public onDateChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.form.date = `${this.date.year}/${("0" + this.date.month).substr(
        -2
      )}/${("0" + this.date.day).substr(-2)}`;
      this.checkAvailability();
    }
  }

  @Watch("form.office_id")
  public onOfficeChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.checkAvailability();
    }
  }

  @Watch("date.month")
  public onMonthChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.getDays();
    }
  }

  private getDays(): void {
    let currentMonth = moment().format("jM");
    let currentDay = Number.parseInt(moment().format("jD"));
    let days = [];
    if (currentMonth == this.date.month) {
      for (let i = 2; i <= 31 - currentDay; i++) {
        let isFriday = moment(
          `${this.date.year}/${this.date.month}/${currentDay + i}`,
          "jYYYY/jMM/jD"
        ).format("d");
        if (isFriday == "5") continue;
        days.push({
          text: `${currentDay + i}`,
          value: `${currentDay + i}`
        });
      }
    } else {
      for (let i = 1; i <= 31; i++) {
        let isFriday = moment(
          `${this.date.year}/${this.date.month}/${i}`,
          "jYYYY/jMM/jD"
        ).format("d");
        if (isFriday == "5") continue;
        days.push({
          text: `${i}`,
          value: `${i}`
        });
      }
    }
    this.days = days;
  }
}
