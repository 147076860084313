import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";

export default class EventUtil {
  public async checkAvailability(
    type: string,
    date: string,
    office_id: string,
    time: Array<Record<string, string>>
  ): Promise<Array<Record<string, string>>> {
    const form = {
      office_id,
      date,
      type
    };

    const refactor = form;
    Object.keys(form).forEach(
      (key) => !(refactor as any)[key] && delete (refactor as any)[key]
    );
    if (Object.keys(form).length < 2) return time;

    try {
      const res: AxiosResponse = await new UserApi().checkAvailability(form);
      const times: Record<string, string>[] = [];
      time.map((x) => {
        let insert = false;
        for (const y of res.data.data) {
          if (!y.cnt) {
            continue;
          }
          if (y.time.substr(0, 5) == x.value) {
            times.push({
              text: `${x.text}`,
              value: `${x.value}`,
              cnt: `${y.cnt}`
            });
            insert = true;
          }
        }
        if (!insert) {
          times.push(x);
        }
      });
      return times;
    } catch (error: any) {
      console.log(error);
      throw new error();
    }
  }
}
